

import { SmartStorage } from "smart-core-util";
export const orderSettleBase = {
    'WineAmount': {
        required: true,
        description: '请填写酒水金额',
        regExp:/(^[1-9](\d+)?(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确酒水金额'
    }, 
    'originAmount': {
        required: true,
        description: '请填写消费总金额',
        regExp:/(^[1-9](\d+)?(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确消费总金额'
    },            
    'qty': {
        required: true,
        description: '请填写正确人数',
        regExp:/^\+?[1-9][0-9]*$/,
        regExpMsg:'请填写正确人数'            
    },       
    'hasFiles': {
        required: true,
        description: '请上传至少一张水单'
    }
}
export const orderSettleIpsen = {
    'WineAmount': {
        required: SmartStorage.get('eventData').typeDictTxt == '院外拜访' ? false : true,
        description: '请填写酒水金额',
        regExp:/(^[1-9](\d+)?(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确酒水金额'
    }, 
    'originAmount': {
        required: true,
        description: '请填写消费总金额',
        regExp:/(^[1-9](\d+)?(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确消费总金额'
    },            
    'qty': {
        required: true,
        description: '请填写正确人数',
        regExp:/^\+?[1-9][0-9]*$/,
        regExpMsg:'请填写正确人数'            
    },       
    'hasFiles': {
        required: true,
        description: '请上传至少一张水单'
    }
}

export const orderSettleNor = {
    'originAmount': {
        required: true,
        description: '请填写消费总金额',
        regExp:/(^[1-9](\d+)?(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确消费总金额'
    },            
    'qty': {
        required: true,
        description: '请填写正确人数',
        regExp:/^\+?[1-9][0-9]*$/,
        regExpMsg:'请填写正确人数'            
    },       
    'hasFiles': {
        required: true,
        description: '请上传至少一张水单'
    }
}

const orderSettlePfizer = {
    'originAmount': {
        required: true,
        description: '请填写消费总金额',
        regExp:/(^[1-9](\d+)?(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确消费总金额',
        expression: function(m) {
            return parseInt(m.amount)>30000
        },
        expressionMsg: '消费总金额超3万，不允许提交。'
    },
    'hasFiles': {
        required: true,
        description: '请上传至少一张水单'
    }
}

const orderSettleRoche = {
    'originAmount': {
        required: true,
        description: '请填写消费总金额',
        regExp:/(^[1-9](\d+)?(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确消费金额'
    },
    'WineAmount': {
        required: true,
        description: '请填写酒水金额',
        regExp:/(^[1-9](\d+)?(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确酒水金额'
    },
    'qty': {
        required: true,
        description: '请填写正确人数',
        regExp:/^\+?[1-9][0-9]*$/,
        regExpMsg:'请填写正确人数'
    },
    'hasFiles': {
        required: true,
        description: '请上传至少一张水单'
    }
}

const orderSettleDscl = {
    'originAmount': {
        required: true,
        description: '请填写消费总金额',
        regExp:/(^[1-9](\d+)?(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确消费总金额'
    },    
    'WineAmount': {
        required: true,
        description: '请填写酒水金额',
        regExp:/(^[1-9](\d+)?(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确酒水金额',
        expression: function(m) {
            return (m.WineAmount/m.originAmount) > (30/100)
        },
        expressionMsg: '外出用餐酒水金额不可超总金额的30%'
    },         
    'qty': {
        required: true,
        description: '请填写正确人数',
        regExp:/^\+?[1-9][0-9]*$/,
        regExpMsg:'请填写正确人数',
        expression: function(m) {
            return m.originAmount>m.qty*300
        },
        expressionMsg: '外出用餐人均不能超300元'         
    },       
    'hasFiles': {
        required: true,
        description: '请上传至少一张水单'
    }
}

const orderSettleCrossevent = {
    'originAmount': {
        required: true,
        description: '请填写消费总金额',
        regExp:/(^[1-9]\d{0,6}(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确消费总金额',
        expression: function(m) {
            return m.originAmount == '0'
        },
        expressionMsg: '消费金额不能为0' 
    },          
    'qty': {
        required: true,
        description: '请填写正确人数',
        regExp:/^[1-9]\d{0,3}$/,
        regExpMsg:'请填写正确人数',       
    },       
    'hasFiles': {
        required: true,
        description: '请上传至少一张水单'
    }
}
export const orderSettleHr = {
    'originAmount': {
        required: true,
        description: '请填写消费总金额',
        regExp:/(^[1-9]\d{0,6}(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确消费总金额',
        expression: function(m) {
            return m.originAmount == '0'
        },
        expressionMsg: '消费金额不能为0' 
    },          
    'qty': {
        required: true,
        description: '请填写正确人数',
        regExp:/^[1-9]\d{0,3}$/,
        regExpMsg:'请填写正确人数',       
    }
}

const orderSettleNovartis = {
    'originAmount': {
        required: true,
        description: '请填写消费总金额',
        regExp:/(^[1-9](\d+)?(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确消费总金额'
    },    
    'WineAmount': {
        required: true,
        description: '请填写酒水金额',
        regExp:/(^[1-9](\d+)?(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确酒水金额'
    },         
    'qty': {
        required: true,
        description: '请填写正确人数',
        regExp:/^\+?[1-9][0-9]*$/,
        regExpMsg:'请填写正确人数',
        expression: function(m) {
            return m.originAmount>m.qty*300
        },
        expressionMsg: '外出用餐人均不能超300元'         
    },       
    'hasFiles': {
        required: true,
        description: '请上传至少一张水单'
    }
}

export const orderSettle= {
    novartis: orderSettleNovartis,
    dscl: orderSettleDscl,
    crossevent: orderSettleCrossevent,
    eventcool: orderSettleBase,
    pfizer: orderSettlePfizer,
    roche: orderSettleRoche
}